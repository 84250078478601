export const companyArray = [
  {
    sheetName: '$AAPL',
    companyName: 'Apple',
    revenueQuarter: 1,
    revenueRow: 7,
    revenueGrowthRow: 8,
    netIncomeRow: 34,
    grossMarginRow: 21,
    icon: require('@/assets/icons/apple.svg'),
    color: '#39DAFF',
    additional_values: [],
  },
  {
    sheetName: '$META',
    companyName: 'Meta',
    revenueQuarter: 1,
    revenueRow: 3,
    revenueGrowthRow: 4,
    netIncomeRow: 25,
    grossMarginRow: 9,
    icon: require('@/assets/icons/meta.svg'),
    color: '#31BFE2',
    additional_values: [],
  },
  {
    sheetName: '$MSFT',
    companyName: 'Microsoft',
    revenueQuarter: 1,
    revenueRow: 7,
    revenueGrowthRow: 8,
    netIncomeRow: 30,
    grossMarginRow: 14,
    icon: require('@/assets/icons/microsoft.svg'),
    color: '#29A5C5',
    additional_values: [      
      '51,728',
      '49,360',
      '51,865',
      '50,122',
      '52,747',
      '52,857',
      '56,189',
      '56,517',
      '62,020',
      '61,858',
      '64,727',
      '65,585',
    ],
  },
  {
    sheetName: '$GOOG',
    companyName: 'Google',
    revenueQuarter: 1,
    revenueRow: 3,
    revenueGrowthRow: 4,
    netIncomeRow: 45,
    grossMarginRow: 29,
    icon: require('@/assets/icons/google.svg'),
    color: '#218AA8',
    additional_values: [],
  },
  {
    sheetName: '$AMZN',
    companyName: 'Amazon',
    revenueQuarter: 1,
    revenueRow: 7,
    revenueGrowthRow: 8,
    netIncomeRow: 39,
    grossMarginRow: 13,
    icon: require('@/assets/icons/amazon.svg'),
    color: '#196F8C',
    additional_values: [],
  },
  {
    sheetName: '$TSLA',
    companyName: 'Tesla',
    revenueQuarter: 1,
    revenueRow: 12,
    revenueGrowthRow: 13,
    netIncomeRow: 45,
    grossMarginRow: 26,
    icon: require('@/assets/icons/tesla.svg'),
    color: '#11546F',
    additional_values: [      
      '16,934',
      '21,454',
      '24,318',
      '23,329',
      '24,927',
      '23,350',
      '25,167',
      '21,301',
      '25,500',
      '25,182',
      '',
      '',
    ],
  },
  {
    sheetName: '$NVDA',
    companyName: 'Nvidia',
    revenueQuarter: 1,
    revenueRow: 3,
    revenueGrowthRow: 4,
    netIncomeRow: 27,
    grossMarginRow: 9,
    icon: require('@/assets/icons/nvidia.svg'),
    color: '#093A52',
    additional_values: [],
  },
];
